import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Header from "../layout/header";
import "./index.css";
import Product from "../product";
import { isDev } from "../../utils/helpers";

const products = [
  {
    key: 1,
    name: "Tifin Wealth",
    group: "TIFIN-Grow",
    logo: "https://tifinwealth.com/wp-content/uploads/logo.png",
    tagline:
      "A platform to help advisors understand their prospect’s fears, needs, and wants through risk, planning and personality assessments.",
    redirectUri: isDev()
      ? "https://dev.tifingrow.com"
      : "https://app.tifingrow.com",
    height: "38px",
    className: "img-name img-name130",
    buttonClass: "common-button-black",
  },
  {
    key: 2,
    name: "Tifin Risk",
    group: "TIFIN-Risk",
    logo: "https://tifinwealth.com/wp-content/uploads/2022/01/image-13-1.png",
    tagline:
      "Conversational, AI-based micro-planning that answers your prospect’s most pressing financial questions.",
    redirectUri: isDev()
      ? "https://dev.web.totumrisk.com/login/v1"
      : "https://web.totumrisk.com/login/v1",
    height: "38px",
    className: "",
    buttonClass: "common-button-blue",
  },
  // {
  //     key: 3,
  //     name: 'Tifin Personality',
  //     group: 'TIFIN-Personality',
  //     logo: 'https://tifinwealth.com/wp-content/uploads/2022/01/image-11-1.png',
  //     tagline: 'Put Your Money Where Your Mind Is Identify your dominant financial personality traits. Align your financial personality to your investment views.',
  //     redirectUri: isDev() ? 'https://advisor-beta.positivly.com/login-via-dashboard' : 'https://advisor.positivly.com/login-via-dashboard',
  //     height:'38px',
  //     className:"",
  //     buttonClass:"common-button-green"
  // },
  // {
  //     key: 3,
  //     name: 'Tifin Plan',
  //     group: 'TIFIN-Plan',
  //     logo: 'https://tifinwealth.com/wp-content/uploads/2022/01/image-12-1.png',
  //     tagline: 'Conversational, AI-based micro-planning that answers your prospect’s most pressing financial questions.',
  //     redirectUri: isDev() ? 'https://app-dev.planiq.com/implicit/callback' : 'https://app.planiq.com',
  //     height:'38px',
  //     className:"",
  //     buttonClass:"common-button-yellow"
  // },
  {
    key: 3,
    name: "Tifin Give",
    group: "TIFIN-Give",
    logo: "https://tifinwealth.com/wp-content/uploads/2022/06/Frame-1.png",
    tagline:
      "A platform to introduce charitable giving into your business through personalized DAFs to engage the entire family and giving aligned investing.",
    redirectUri: isDev()
      ? "https://demo.meetlouise.com/"
      : "https://app.meetlouise.com/",
    height: "38px",
    className: "",
    buttonClass: "common-button-maroon",
  },
  {
    key: 4,
    name: "Tifin TPM",
    group: isDev() ? "TIFIN Private Markets Demo" : "TIFIN Private Markets",
    logo: "https://app.tifinprivatemarkets.com/qualisBlackLogo.svg",
    tagline:
      "Experience the next generation of alternative investments. Our AI-powered digital marketplace helps improve access and drive adoption of alternative investments through lower fees and minimums, reduced friction, and a curated selection of funds.",
    redirectUri: isDev()
      ? "https://demo.tifinprivatemarkets.com/"
      : "https://app.tifinprivatemarkets.com/",
    height: "38px",
    className: "",
    buttonClass: "common-button-black",
  },
  {
    key: 5,
    name: "Tifin Clout",
    group: isDev() ? "TIFIN Clout Advisor" : "Tifin-Clout",
    logo: "https://mycontent-statics.myclout.com/assets/images/TIFIN-Clout-Logo-Color.png",
    tagline:
      "An AI-powered, hyper-personalized, content marketing platform to power sales in financial services.",
    redirectUri: isDev()
      ? "https://react-dev.marketinsightout.com/"
      : "https://apps.myclout.com/",
    height: "38px",
    className: "",
    buttonClass: "common-button-black",
  },
  {
    key: 6,
    name: isDev() ? "Ronald Blue Trust UAT" : "Ronald Blue Trust",
    group: "TIFIN Give - RBT All Users",
    logo: "https://release.bluegive.org/assets/enterprise/logo.png",
    tagline: "",
    redirectUri: isDev()
      ? "https://uat.bluegive.org/#/auth"
      : "https://bluegive.org/",
    height: "38px",
    className: "",
    buttonClass: "",
  },
  {
    key: 7,
    name: "Tifin TPM",
    group: "TIFIN Private Markets Demo",
    logo: "https://app.tifinprivatemarkets.com/qualisBlackLogo.svg",
    tagline:
      "Experience the next generation of alternative investments. Our AI-powered digital marketplace helps improve access and drive adoption of alternative investments through lower fees and minimums, reduced friction, and a curated selection of funds.",
    redirectUri: "https://demo.tifinprivatemarkets.com/",
    height: "38px",
    className: "",
    buttonClass: "common-button-black",
  },
];

const Dashboard = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getUser();
  }, []);

  console.log(authState);

  const getUser = async () => {
    const user = await oktaAuth.getUser();
    const userGroups = products
      .filter((product) => user.groups.includes(product.group))
      .map((product) => product.group);

    console.log(userGroups, "userGroups....");
    setGroups(userGroups);
    if (userGroups.length === 1) {
      const product = products.find((product) =>
        userGroups.includes(product.group)
      );
      window.location.href = product.redirectUri;
      return;
    }
    setLoading(false);
  };

  const handleRedirect = (redirectUri) => {
    window.location.href = redirectUri;
  };

  if (loading) {
    return <div className="d-flex justify-content-center">Loading....</div>;
  }

  return (
    <div>
      <Header></Header>
      <h2 className="container topTextContent heading">
        {groups.length === 0
          ? "You Are Not A Part Of Any Group"
          : "Please Choose A Product To Continue"}
      </h2>
      <div className="container cardContiner">
        <div className="d-flex justify-content-evenly elementor-container">
          <div className="row">
            {products
              .filter((product) => groups.includes(product.group))
              .map((product) => (
                <Product onContinue={handleRedirect} {...product} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
