import { Fragment } from 'react';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import config from './config';
import { Route, useHistory } from 'react-router-dom';
import {
  OktaAuth,
  toRelativeUrl
} from '@okta/okta-auth-js';
import { Helmet } from "react-helmet";

import Dashboard from './components/dashboard';
import './App.css';
import { isDev } from './utils/helpers';

const oktaAuth = new OktaAuth(config.OKTA_AUTH)

const App = () => {


  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Fragment>
      {
        isDev() &&
        <Helmet>
          <title>TIFIN Wealth Dev Dashboard</title>
          <meta name="description" content="TIFIN Wealth Dev Dashboard" />
        </Helmet>
      }
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Route path='/login/callback' component={LoginCallback} />
        <SecureRoute path='/' exact={true} component={Dashboard} />
      </Security>
    </Fragment>
  );
}

export default App;
